html,
body {
  font-family: "THSarabunNew", sans-serif;
  scroll-behavior: smooth;
}
@font-face {
  font-family: myFirstFont;
  src: url(./fonts/thsarabunnew-webfont.woff);
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: myFirstFont, sans-serif;
}
body {
  background-image: url("./image/men.jpg");
  background-size: cover;
  background-position: right top;
}
