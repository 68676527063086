.nav-link {
  cursor: pointer;
}
.nav-link.active {
  border: none;
  background-color: #6c757d !important;
}
.nav-link:hover {
  border: none;
}
.theada2 {
  background-color: #033514;
}
.roundcol {
  padding-left: 15px;
  padding-right: 10px;
}
