.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000093;
  border-radius: 50px;
  padding: 20px;
}

.bts-bts {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  border-radius: 6px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  border: 2px solid #e7e7e7 !important;
}
.textHead {
  text-align: center;
  margin-top: 110px;
  margin-bottom: 50px;
  font-size: 80px;
  font-weight: bolder;
  color: white;
}
.mr-betaween {
  margin-bottom: 30px;
}
.textSub {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: bolder;
  color: white;
}
.textSub1 {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: bolder;
  color: black;
}
.ip {
  width: 400px !important;
}
.namesub1 {
  padding: 10px;
  width: 15%;
  color: #000000;
}
.namesub2 {
  padding: 8px;
  width: 20%;
  color: #000000;
}
.tableMoney {
  padding: 8px;
  width: 20%;
  color: #000000;
}
.tableDate {
  padding: 8px;
  width: 20%;
  color: #000000;
}
.theada {
  text-align: center;
  background-color: #deff98;
  font-weight: bold;
}
.tra {
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}
.boxT {
  background-color: #000000;
}
.sizeSearch {
  padding-left: 20px !important;
  width: 400px !important;
  height: 40px !important;
  font-size: 18px !important;
  border-radius: 15px !important;
  margin-bottom: 10px !important;
}
.IpS {
  border: 2px solid red !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 6px 5px rgb(255, 25, 121);
}
